.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  > svg {
    width: 100%;
    height: 100%;
  }
}

.for-button {
  width: 1.2rem;
  height: 1.2rem;
  > svg {
    width: 100%;
    height: 100%;
  }
}
