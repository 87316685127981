button.btn,
a.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  border: 1.5px solid transparent;
  transition: all 0.5s;
  cursor: pointer;
  min-height: 40px;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &--primary {
    @apply text-white bg-primary;
    &:hover {
      @apply bg-primary-hover;
    }
  }

  &--primary-outline {
    @apply text-primary bg-white border-primary;
    &:hover {
      @apply bg-primary;
      @apply text-white;
    }
  }

  &--secondary {
    @apply text-white bg-secondary;
    &:hover {
      @apply bg-secondary-hover;
    }
  }

  &--secondary-outline {
    @apply text-secondary bg-white border-secondary;
    &:hover {
      @apply bg-secondary;
      @apply text-white;
    }
  }

  &--tertiary {
    @apply text-white bg-tertiary;
    &:hover {
      @apply bg-tertiary-dark;
    }
  }

  &--tertiary-outline {
    @apply text-tertiary bg-white border-tertiary;
    &:hover {
      @apply bg-tertiary;
      @apply text-white;
    }
  }

  &--pagination {
    @apply text-white bg-primary px-4 py-2;
    &:hover {
      @apply bg-primary-hover;
    }
  }

  &--success {
    @apply text-white bg-success;
    &:hover {
      @apply bg-success-light;
    }
  }

  &--success-outline {
    @apply text-success bg-white border-success;
    &:hover {
      @apply bg-success;
      @apply text-white;
    }
  }

  &--warning {
    @apply text-white bg-warning;
    &:hover {
      @apply bg-warning-light;
    }
  }

  &--warning-outline {
    @apply text-warning bg-white border-warning;
    &:hover {
      @apply bg-warning;
      @apply text-white;
    }
  }

  &--error {
    @apply text-white bg-error;
    &:hover {
      @apply bg-error-light;
    }
  }

  &--error-outline {
    @apply text-error bg-white border-error;
    &:hover {
      @apply bg-error;
      @apply text-white;
    }
  }

  &--danger {
    @apply text-white bg-error;
    &:hover {
      @apply bg-error-light;
    }
  }

  &--upload-file {
    border: 2px dashed var(--primary-light);
    color: var(--blue-medium);
    padding: 0.5rem 0.75rem;
    &:hover {
      color: var(--primary-light);
      border: 3px dashed var(--primary-light);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
