.textarea {
  padding: 0.8rem;
  width: 100%;
  border-radius: var(--input-border-radius);
  &:focus {
    border: 1px solid var(--blue);
    outline: none;
  }
  &--invalid {
    color: var(--error);
    &::placeholder {
      color: var(--error) 87;
    }
  }
}
