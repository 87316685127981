.input {
  padding: 0.8rem;
  border-radius: var(--input-border-radius);
  border: 1px solid var(--gray-light);
  font-weight: 600;
  font-size: 16px;

  &:focus:not(.input--invalid) {
    border: 1px solid var(--blue);
    outline: none;
  }
  &--invalid {
    color: var(--error);
    border: 1px solid var(--error);
    &::placeholder {
      color: var(--error) 87;
    }
  }

  &--disabled {
    background-color: var(--gray-lightest);
    color: var(--gray-dark);
    border: none;
    cursor: not-allowed;
  }

  &--toggle-password {
    position: absolute;
    top: 0rem;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 100%;
    padding: 0.6rem;
    > svg {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }

    &:hover {
      color: var(--gray-dark);
    }
  }
}
